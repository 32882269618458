'use client'
import { useForm } from "react-hook-form";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { motion } from "framer-motion";
import { Input, Button, useToast } from "@/components/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { requestResetPasswordSchema } from "../schema/Auth";
import { useSelector } from "react-redux";
import { requestResetPassword } from "@/services/auth";

export default function RequestResetPassword() {
    const { url } = useSelector((store) => store.Global);
    const { toast } = useToast();
    const resetPasswordform = useForm({
        resolver: zodResolver(requestResetPasswordSchema),
        defaultValues: {
            email: "",
        },
    });

    const onPassworReset = async (values) => {
        const { email } = values;
        const { error, msg } = await requestResetPassword(email)
        if (!error) {
            toast({
                title: "Success",
                description: `Email sent to ${email}`,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: msg,
            });
        }
    }

    return (
        <>
            <h1 className="text-3xl xs:text-3xl sm:text-4xl m-4 responsiveHeading2 font-semibold">
                Reset password
            </h1>
            <p className="responsiveText text-center max-w-[600px]">
                Please enter your registered email address so that we can send you a
                link to reset your password.
            </p>
            <motion.div
                className="w-[min(500px,100%)] space-y-4"
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }}
            >
                <Form {...resetPasswordform}>
                    <form
                        onSubmit={resetPasswordform.handleSubmit(onPassworReset)}
                        encType="multipart/form-data"
                        className="space-y-4 min-w-full"
                    >
                        <FormField
                            control={resetPasswordform.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="customInput"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className="min-w-full">
                            <Button type="submit" className="text-xl py-6 min-w-full ">
                                Request Reset
                            </Button>
                        </div>
                    </form>
                </Form>
            </motion.div>
        </>
    );
}