"use client";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import Image from "next/image";
import Link from "next/link";
import { Spinner } from "@/components/Loading/Loading";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input, Button, useToast } from "@/components/ui";
import google from "@/public/google.svg";
import { signIn } from "next-auth/react";
import { IoIosArrowBack } from "react-icons/io";
import { userLoginSchema } from "@/components/schema/Auth";
import RequestResetPassword from "@/components/Auth/RequestRestPassword";
import { Title } from "@/components/Globals/Globals";

export default function Login() {
  const { toast } = useToast();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);

  // Signin Function
  const handleSignIn = async () => {
    setGoogleLoading(true);
    await signIn("google");
    setGoogleLoading(false);
  };

  // Credentials form
  const form = useForm({
    resolver: zodResolver(userLoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // Credentials Login
  async function onSubmit(values) {
    setLoading(true);
    const result = await signIn("credentials", {
      email: values.email,
      password: values.password,
      redirect: false,
    });
    setLoading(false);
    if (result.error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: result.error,
      });
    }
  }
  if (forgotPassword)
    return (
      <div className="relative flex flex-col items-center min-h-[90vh] justify-center p-10">
        <Link href="/">
          <Title
            classname={"hidden sm:block absolute top-10 left-10 text-4xl"}
          />
        </Link>
        <div className="grid grid-cols-1 place-items-center space-y-10">
          <div
            onClick={() => setForgotPassword(false)}
            className="place-self-start border rounded-md p-2 max-w-[35px]   hover:bg-gray-300/40 border-black cursor-pointer"
          >
            <IoIosArrowBack />
          </div>
          <RequestResetPassword />
        </div>
      </div>
    );

  return (
    <div className="relative flex items-center justify-center fullHeight px-3 min-w-screen">
      <Title classname={"hidden sm:block absolute top-10 left-10 text-4xl"} />
      <div className="flex flex-col  items-center w-full max-w-[500px] space-y-7 px-3">
        <h1 className="min-w-full m-4 text-3xl font-bold xs:text-3xl sm:text-4xl">
          Sign in to Interview
        </h1>
        <button
          onClick={handleSignIn}
          disabled={googleLoading}
          className="flex items-center justify-center min-w-full p-5 font-semibold border border-gray-300 rounded-xl "
        >
          {googleLoading ? (
            <Spinner />
          ) : (
            <>
              <Image src={google} alt="" className="h-7" />
              <p>Sign in with google</p>
            </>
          )}
        </button>
        <span className="flex items-center justify-center min-w-full text-sm text-gray-400 ">
          <span className="h-[2px] w-full bg-gray-200 z-0"></span>
          <p className="z-10 px-2 whitespace-nowrap">or sign in with email</p>
          <span className="h-[2px] w-full bg-gray-200 z-0"></span>
        </span>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            encType="multipart/form-data"
            className="grid min-w-full grid-cols-2 gap-6"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="min-w-full col-span-2">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input className="customInput" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="min-w-full col-span-2">
                  <div className="flex items-center justify-between">
                    <FormLabel>Password</FormLabel>
                    <p
                      onClick={() => setForgotPassword(true)}
                      className="text-sm text-right font-semibold hover:font-bold hover:underline cursor-pointer"
                    >
                      forgot password?
                    </p>
                  </div>
                  <FormControl>
                    <Input type="password" className="customInput" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="min-w-full col-span-2 ">
              <Button
                type="submit"
                className="min-w-full py-6 text-xl flex items-center justify-center"
                disabled={loading}
              >
                {loading ? <Spinner /> : "Login"}
              </Button>
            </div>
          </form>
          <p className="mx-1 my-2 text-sm">
            Don&apos;t have an account?{"  "}
            <Link className="underline" href="/register">
              Sign up
            </Link>
          </p>
        </Form>
      </div>
    </div>
  );
}
